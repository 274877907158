
import statusQueryMixin from "@/mixins/status-query"
import { storageKeys, getSs, removeSs, setSs } from "@/utils/storage"
import type { ApiFile } from "@/types/api/file"

interface Opts {
  useState: boolean
  state?: string
  code: string
  parseOwner: Function
}

const MANUAL_REGISTRATION = false
const REG_CLOSED = true

export default statusQueryMixin().extend({
  middleware: "auth",
  auth: "guest",

  async mounted () {
    await this.oauth()
  },

  data: () => ({
    exists: false,
    closed: false,
  }),

  methods: {
    q (key: string): string {
      return String(this.$route.query[key] || "")
    },

    fail (statusCode: number, message?: string) {
      this.$nuxt.error({ statusCode, message })
    },

    async login (token: string) {
      await this.$auth.setUserToken(token)
      this.$router.replace(this.localePath("/user"))
    },

    async oauth () {
      const { fail, login } = this

      const { provider } = this.$route.params
      if (!provider) return fail(404)
      const opts = this.getOpts(provider)
      if (!opts) return fail(404)

      const redirectUrl = window.location.href.split("?")[0]

      if (
        opts.code &&
        (!opts.useState || (opts.useState && opts.state))
      ) {
        if (opts.useState) {
          if (opts.state !== getSs(storageKeys.oauthState)) return fail(403)
          removeSs(storageKeys.oauthState)
        }

        let [res, err] =
          await this.$api.login.getOwnerData(
            provider, redirectUrl, String(opts.code))
        if (err) {
          const exists = Boolean(err.context?.email)
          if (exists) {
            this.exists = true
            return
          }
          return fail(403, err.message)
        }
        if (!res) return fail(500)

        const { authToken } = res
        if (authToken) {
          await login(authToken)
        } else if (REG_CLOSED) {
          this.closed = true
        } else {
          const [parsedOwner, parseOwnerErr] = await opts.parseOwner(res.owner)
          if (parseOwnerErr || !parsedOwner) return fail(500)
          const user = {
            ...parsedOwner,
            oauth: res.registrationToken,
            // status: await this.getStatusFromQuery(),
          }

          if (MANUAL_REGISTRATION) {
            this.$store.commit("user/SET_REGISTRATION_MODEL", user)
            this.$router.replace(this.localePath("/auth/registration"))
          } else {
            [res, err] = await this.$api.user.createUser(user)
            if (err) return fail(400)
            if (!res || !res.token) return fail(500)
            await login(res.token)
          }
        }
      } else {
        const [res, err] =
          await this.$api.login.getOauthUrl(provider, redirectUrl)

        if (err) return fail(404)
        if (!res || !res.url) return fail(500)

        if (opts.useState) {
          if (!res.state) return fail(500)
          setSs(storageKeys.oauthState, res.state)
        }

        window.location.replace(res.url)
      }
    },

    async getPhoto (url: string):
    Promise<[Nullable<ApiFile["path"]>, Nullable<string>]> {
      let [res, err] = await this.$api.upload.getProxiedFile(url, {
        responseType: "blob",
      })
      if (err) return [null, err]
      if (!res) return [null, null];

      [res, err] = await this.$api.upload.uploadTmpFile(res)
      if (err) return [null, err]
      const { path } = res
      if (!path) return [null, null]

      res = MANUAL_REGISTRATION ? { url, path } : path
      return [res, null]
    },

    getOpts (provider: string): Nullable<Opts> {
      const { q } = this
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const defaultOpts = {
        useState: true,
        state: q("state"),
        code: q("code"),
        parseOwner: () => ([{}, null]),
        manual: false,
      }

      return {
        // runetid: {
        //   ...defaultOpts,
        //   useState: false,
        //   code: q("token"),
        //   parseOwner: async (owner: any) => {
        //     const patronymic = owner.FatherName
        //     const company = owner.Work?.Company?.Name
        //     const position = owner.Work?.Position
        //     let photo: undefined | string
        //     const [res, err] = await this.getPhoto(owner.Photo.Large)
        //     if (!err) photo = res || undefined
        //     return [{
        //       email: owner.Email || undefined,
        //       firstName: { ru: owner.FirstName || undefined },
        //       lastName: { ru: owner.LastName || undefined },
        //       patronymic: patronymic ? { ru: patronymic } : undefined,
        //       phone: owner.Phone || undefined,
        //       company: company ? { ru: company } : undefined,
        //       position: position ? { ru: position } : undefined,
        //       photo,
        //     }, null]
        //   },
        // },

        // vkontakte: {
        //   ...defaultOpts,
        //   parseOwner: async (owner: any) => {
        //     let photo: undefined | string
        //     if (owner.has_photo) {
        //       const [res, err] = await this.getPhoto(owner.photo_max)
        //       if (!err) photo = res || undefined
        //     }
        //     return [{
        //       email: owner.email || undefined,
        //       firstName: { ru: owner.first_name },
        //       lastName: { ru: owner.last_name },
        //       photo,
        //       profileLink: `https://vk.com/id${owner.id}`,
        //     }, null]
        //   },
        // },

        // yandex: {
        //   ...defaultOpts,
        //   parseOwner: async (owner: any) => {
        //     let photo: undefined | string
        //     if (!owner.is_avatar_empty) {
        //       const [res, err] = await this.getPhoto(
        //         `https://avatars.yandex.net/get-yapic/${
        //           owner.default_avatar_id}/islands-200`,
        //       )
        //       if (!err) photo = res || undefined
        //     }
        //     return [{
        //       email: owner.default_email || undefined,
        //       phone: owner.default_phone?.number || undefined,
        //       firstName: { ru: owner.first_name || undefined },
        //       lastName: { ru: owner.last_name || undefined },
        //       photo,
        //     }, null]
        //   },
        // },

        // odnoklassniki: {
        //   ...defaultOpts,
        //   useState: false,
        //   parseOwner: async (owner: any) => {
        //     let photo: undefined | string
        //     if (owner.pic_3) {
        //       const [res, err] = await this.getPhoto(owner.pic_3)
        //       if (!err) photo = res || undefined
        //     }
        //     return [{
        //       email: owner.email || undefined,
        //       firstName: { ru: owner.first_name || undefined },
        //       lastName: { ru: owner.last_name || undefined },
        //       photo,
        //       profileLink: `https://ok.ru/profile/${owner.uid}`,
        //     }, null]
        //   },
        // },

        bp: {
          ...defaultOpts,
          parseOwner: (owner: any) => {
            const { patronymic } = owner
            return [{
              email: owner.email || undefined,
              firstName: {
                ru: (owner.firstName || "").substring(0, 50) || undefined,
              },
              lastName: {
                ru: (owner.lastName || "").substring(0, 50) || undefined,
              },
              patronymic: patronymic
                ? {
                  ru: patronymic.substring(0, 50),
                }
                : undefined,
              // birthdate: owner.birthdate || undefined,
              "date-birth": owner.birthdate || undefined,
            }, null]
          },
        },
      }[provider] || null
    },
  },
})
